<template>
    <b-overlay :show="isLoading">
        <b-container v-cloak>
            <b-row class="mt-2 mr-4" v-if="supplierData.id <= 0">
                <b-col cols="12" offset="12" class="text-right" style="font-size: 1.3em">
                    <a :class="language === 'en' ? 'font-bold text-primary mr-4' : 'mr-4 text-primary'"
                       @click="changeLanguage('en')">en</a>
                    <a :class="language === 'es' ? 'font-bold text-primary' : 'text-primary'"
                       @click="changeLanguage('es')">es</a>
                </b-col>
            </b-row>
            <b-row align-v="stretch" class="full-height justify-content-md-center mr-4 ml-4 mt-2 mb-4">
                 <b-card>
                     <template #header >
                         <div class="d-flex justify-content-between align-items-center" v-if="supplierData.id <= 0">
                             <h4>{{ $t('signUp') }}</h4>
                             <div class="float-right">
                                 <strong>{{ $t('haveAnAccount') }}</strong>
                                 <b-button type="button" variant="outline-primary" class="ml-4" :to="{name: 'Login'}" >
                                     {{ $t('login') }}
                                 </b-button>
                             </div>
                         </div>
                         <div class="d-flex justify-content-between align-items-center" v-else>
                             <div class="d-flex align-items-start justify-content-start">
                                 <h4>{{ modeEdit ?  ($t('edit') + ' ' + $t('data')) : $t('detail') }}</h4>
<!--                                 <b-badge class="ml-3" pill variant="warning" v-if="user.status === 'TO_VERIFY' && !modeEdit">{{ $t('toVerify') }}</b-badge>-->
<!--                                 <b-badge class="ml-3" pill variant="info" v-else-if="user.status === 'TO_UPDATE' && !modeEdit">{{ $t('toUpdate') }}</b-badge>-->
<!--                                 <b-badge class="ml-3" pill variant="danger" v-else-if="user.status === 'REJECTED' && !modeEdit">{{ $t('rejected') }}</b-badge>-->
<!--                                 <b-badge class="ml-3" pill variant="success" v-else-if="user.status === 'APPROVED'">{{ $t('active') }}</b-badge>-->
<!--                                 <b-badge class="ml-3" pill variant="danger" v-else>{{ $t('inactive') }}</b-badge>-->
                             </div>
                             <div class="float-right">
                                 <b-button type="button" variant="outline-primary" class="ml-4" @click="actionUser = 'EDIT'" v-if="!modeEdit">
                                     <font-awesome-icon icon="edit"></font-awesome-icon> {{ $t('edit') }}
                                 </b-button>
                                 <b-button type="button" variant="outline-primary" class="ml-4" @click="actionUser = 'DETAIL'" v-if="modeEdit">
                                     <font-awesome-icon icon="times"></font-awesome-icon> {{ $t('cancel') }}
                                 </b-button>
                                 <b-button type="button" variant="outline-primary" class="ml-4" :to="{name: 'Home'}" v-if="user.id > 0">
                                     <font-awesome-icon icon="angle-left"></font-awesome-icon> {{ $t('back') }}
                                 </b-button>
                             </div>
                         </div>
                     </template>
                    <ValidationObserver ref="observer" v-slot="{ passes }">
                        <template v-if="user.id > 0 && !user.verified && user.status === 'REJECTED'">
                            <b-alert show variant="warning" style="background: #FFEDC8">
                                {{ $t('rejectedMessage') }} <br><br>
                                <strong>{{ $t('comments') }}:</strong> {{ user.reason }}
                            </b-alert>
                        </template>
                        <template v-else-if="user.id > 0 && !user.verified && user.status === 'TO_UPDATE'">
                            <b-alert show variant="warning" style="background: #FFEDC8">
                                <strong>{{ $t('update') + ' ' + $t('data') }}</strong><br>
                                {{ user.reason }}
                            </b-alert>
                        </template>
                        <b-form @submit.prevent="passes(onSubmit)" id="supplier-form">
                            <b-row>
                                <b-col md="4" lg="4" v-if="supplierData.id <= 0">
                                    <ValidationProvider rules="required" name="token" v-slot="{ errors }">
                                        <b-form-group label="Token" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="token" placeholder="Token"
                                                          :state="errors[0] ? false : null"
                                                                autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" lg="3" v-if="supplierData.id <= 0">
                                    <b-form-group :label="$t('foreignSupplier')">
                                        <div class="switch" style="margin-top: -8px">
                                            <div class="onoffswitch">
                                                <input id="isForeignSupplier" type="checkbox" class="onoffswitch-checkbox" v-model="supplierData.isForeignSupplier"
                                                       @click="isForeign">
                                                <label for="isForeignSupplier"  class="onoffswitch-label">
                                                    <span class="onoffswitch-inner"></span>
                                                    <span class="onoffswitch-switch"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" lg="6" v-if="supplierData.id > 0">
                                    <b-form-group :label="$t('foreignSupplier')">
                                        <div class="switch" style="margin-top: -8px">
                                            <div class="onoffswitch">
                                                <input id="isForeignSupplier" type="checkbox" class="onoffswitch-checkbox" v-model="supplierData.isForeignSupplier"
                                                       @click="isForeign" :disabled="!modeEdit">
                                                <label for="isForeignSupplier"  class="onoffswitch-label">
                                                    <span class="onoffswitch-inner"></span>
                                                    <span class="onoffswitch-switch"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </b-form-group>
                                </b-col>
<!--                                <b-col md="3" lg="3">-->
<!--                                    <b-form-group label="Requiere REPSE">-->
<!--                                        <div class="switch">-->
<!--                                            <div class="onoffswitch">-->
<!--                                                <input id="requireRepse" type="checkbox" class="onoffswitch-checkbox" v-model="supplierData.requiredRepse"-->
<!--                                                       @click="requireRepse">-->
<!--                                                <label for="requireRepse"  class="onoffswitch-label">-->
<!--                                                    <span class="onoffswitch-inner"></span>-->
<!--                                                    <span class="onoffswitch-switch"></span>-->
<!--                                                </label>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </b-form-group>-->
<!--                                </b-col>-->
                                <b-col md="4" lg="4"></b-col>
                                <b-col md="4" lg="4">
                                    <ValidationProvider rules="required" name="tradeName" v-slot="{ errors }">
                                        <b-form-group :label="$t('tradeName')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.tradeName" :placeholder="$t('tradeName')"
                                                          :state="errors[0] ? false : null" autocomplete="off" :disabled="!modeEdit"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" lg="4">
                                    <ValidationProvider rules="required" name="legalName" v-slot="{ errors }">
                                        <b-form-group :label="$t('legalName')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.legalName" :placeholder="$t('legalName')"
                                                          :state="errors[0] ? false : null" autocomplete="off" :disabled="!modeEdit"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" lg="4">
                                    <b-form-group :label="$t('capitalRegime')">
                                        <b-form-input v-model="supplierData.legalEntityType" :placeholder="$t('capitalRegime')"
                                                      autocomplete="off" :disabled="!modeEdit"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" lg="4">
                                    <ValidationProvider name="rfc" v-slot="{ errors }">
                                        <b-form-group :label="$t('taxId')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.taxId" :placeholder="$t('taxId')" autocomplete="off"
                                                          :state="errors[0] ? false : null" :disabled="!modeEdit"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="4" lg="4">
                                    <b-form-group :label="$t('industry')">
                                        <b-form-input v-model="supplierData.industry" :placeholder="$t('industry')" :disabled="!modeEdit"
                                                      autocomplete="off"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="4" lg="4">
                                    <b-form-group :label="$t('website')">
                                        <b-form-input v-model="supplierData.webPage" :placeholder="$t('website')" :disabled="!modeEdit"
                                                      autocomplete="off"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <hr>
                            </b-row>
                            <b-row>
                                <b-col md="5" lg="5">
                                    <ValidationProvider rules="required" name="street" v-slot="{ errors }">
                                        <b-form-group :label="$t('street')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.address.street" :placeholder="$t('street')" :disabled="!modeEdit"
                                                          :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="2" lg="2">
                                    <ValidationProvider rules="required" name="extNumber" v-slot="{ errors }">
                                        <b-form-group :label="$t('extNumber')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.address.extNumber" :placeholder="$t('extNumber')"
                                                          :state="errors[0] ? false : null" autocomplete="off" :disabled="!modeEdit"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="2" lg="2">
                                    <b-form-group :label="$t('intNumber')">
                                        <b-form-input v-model="supplierData.address.intNumber" :placeholder="$t('intNumber')"
                                                      autocomplete="off" :disabled="!modeEdit"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" lg="3" v-if="!supplierData.isForeignSupplier">
                                    <ValidationProvider rules="required" name="neighborhood" v-slot="{ errors }">
                                        <b-form-group :label="$t('neighborhood')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.address.neighborhood" :placeholder="$t('neighborhood')"
                                                          :disabled="!modeEdit"
                                                          :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" lg="3" v-if="supplierData.isForeignSupplier">
                                        <b-form-group :label="$t('neighborhood')">
                                            <b-form-input v-model="supplierData.address.neighborhood" :placeholder="$t('neighborhood')"
                                                          :disabled="!modeEdit" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                </b-col>
                                <b-col md="3" lg="3">
                                    <b-form-group :label="$t('borough')">
                                        <b-form-input v-model="supplierData.address.borough" :placeholder="$t('borough')"
                                                      autocomplete="off" :disabled="!modeEdit"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" lg="3">
                                    <ValidationProvider rules="required" name="city" v-slot="{ errors }">
                                        <b-form-group :label="$t('city')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.address.city" :placeholder="$t('city')"
                                                          :disabled="!modeEdit"
                                                          :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" lg="3" v-show="!supplierData.isForeignSupplier">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                         <b-form-group :label="$t('state')" :invalid-feedback="errors[0]" :state="errors[0] ? false : null" :class="{'has-error': errors[0]}">
                                            <select id="states-select" v-model="supplierData.address.state" :disabled="!modeEdit">
                                                <option value=""></option>
                                            </select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" lg="3" v-show="supplierData.isForeignSupplier">
                                    <ValidationProvider rules="required" name="state" v-slot="{ errors }">
                                        <b-form-group :label="$t('state')" :invalid-feedback="errors[0]">
                                        <b-form-input v-model="supplierData.address.state" :placeholder="$t('state')" :disabled="!modeEdit"
                                                 autocomplete="off" :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" lg="3">
                                    <ValidationProvider rules="required" name="country" v-slot="{ errors }">
                                        <b-form-group :label="$t('country')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.address.country" :placeholder="$t('country')"
                                                          :disabled="!modeEdit || !supplierData.isForeignSupplier"
                                                          :state="errors[0] ? false : null" autocomplete="off" ></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" lg="3">
                                    <ValidationProvider rules="required" name="zipCode" v-slot="{ errors }">
                                        <b-form-group :label="$t('zipcode')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.address.zipcode" :placeholder="$t('zipcode')" autocomplete="off"
                                                          :disabled="!modeEdit" :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
<!--                                <b-col md="4" lg="4">-->
<!--                                    <b-form-group label="Moneda">-->
<!--                                        <select id="currency-select" :disabled="!modeEdit">-->
<!--                                            <option value=""></option>-->
<!--                                        </select>-->
<!--                                    </b-form-group>-->
<!--                                </b-col>-->
<!--                                <b-col md="4" lg="4">-->
<!--                                    <b-form-group label="Cuenta de pago">-->
<!--                                        <b-form-input v-model="supplierData.paymentCount" placeholder="Cuenta de pago" autocomplete="off"-->
<!--                                                      :disabled="!modeEdit"></b-form-input>-->
<!--                                    </b-form-group>-->
<!--                                </b-col>-->
                                <b-col md="12" lg="12" class="m-b-sm">
                                    <b-form-group :label="$t('bankInformation')">
                                        <b-form-textarea
                                            rows="3"
                                            no-auto-shrink
                                            :placeholder="$t('bankInformation')"
                                            v-model="supplierData.bankData"
                                            :disabled="!modeEdit"
                                        ></b-form-textarea>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="12" class="mt-3"><h4>{{ $t('accountContact') }}</h4></b-col>
                                <b-col md="6" lg="6">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('name')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.contact.name" :placeholder="$t('name')" :disabled="!modeEdit"
                                                          autocomplete="off" :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" lg="3">
                                    <b-form-group :label="$t('jobPosition')">
                                        <b-form-input v-model="supplierData.contact.jobPosition" :placeholder="$t('jobPosition')"
                                                      :disabled="!modeEdit" autocomplete="off"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" lg="3">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('jobArea')" :invalid-feedback="errors[0]" :state="errors[0] ? false : null" :class="{'has-error': errors[0]}">
                                            <select id="jobArea-select" v-model="supplierData.contact.jobArea" :disabled="!modeEdit">
                                                <option value=""></option>
                                            </select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" lg="3">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group :label="$t('phone')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.contact.phone" :placeholder="$t('phone')" autocomplete="off"
                                                          :state="errors[0] ? false : null" :disabled="!modeEdit"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" lg="3">
                                    <b-form-group :label="$t('extension')">
                                        <b-form-input v-model="supplierData.contact.extension" :placeholder="$t('extension')" autocomplete="off"
                                                      :disabled="!modeEdit"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" lg="3">
                                    <b-form-group :label="$t('cellPhone')">
                                        <b-form-input v-model="supplierData.contact.mobile" :placeholder="$t('cellPhone')" autocomplete="off"
                                                      :disabled="!modeEdit"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="3" lg="3">
                                    <ValidationProvider rules="required|email" v-slot="{ errors }">
                                        <b-form-group :label="$t('email')" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="supplierData.contact.email" :placeholder="$t('email')" :disabled="!modeEdit"
                                                          autocomplete="off" :state="errors[0] ? false : null"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row class="m-t-md" v-if="!supplierData.isForeignSupplier">
                                <b-col lg="12">
                                    <div style="display: flex; flex-direction: row;">
                                        <ValidationProvider :rules="!incorporationActName ? 'required' : ''" v-slot="{ errors }" :style="incorporationAct && modeEdit ? 'width: 98%' : 'width: 100%'">
                                            <b-form-group :label="$t('constitutiveAct')" id="inputOrderFile" class="mb-0" :invalid-feedback="errors[0]">
                                                <b-form-file v-model="incorporationAct" type="file" accept=".xlsx, .xls, .pdf" size="md"
                                                             :browse-text="$t('select')"
                                                             :disabled="!modeEdit" :placeholder="incorporationActName ?? ''" :state="errors[0] ? false : null">
                                                </b-form-file>
                                                <div class="mt-2"></div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <b-button v-if="incorporationAct && modeEdit" @click="deleteFile('INCORPORATION_ACT')" variant="default"
                                                  class="mb-lg-0 mt-auto" style="margin-bottom: -0.5px !important;">
                                            <font-awesome-icon icon="times"></font-awesome-icon>
                                        </b-button>
                                    </div>
                                </b-col>
                                <b-col lg="12">
                                    <div style="display: flex; flex-direction: row;">
                                        <ValidationProvider :rules="!proofTaxSituationName ? 'required' : ''" v-slot="{ errors }" :style="proofTaxSituation && modeEdit ? 'width: 98%' : 'width: 100%'">
                                            <b-form-group :label="$t('taxSituation')" id="inputOrderFile" class="mb-2" :invalid-feedback="errors[0]">
                                                <b-form-file v-model="proofTaxSituation" type="file" accept=".xlsx, .xls, .pdf" size="md"
                                                             :browse-text="$t('select')"
                                                             :disabled="!modeEdit" :placeholder="proofTaxSituationName ?? ''" :state="errors[0] ? false : null">
                                                </b-form-file>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <b-button v-if="proofTaxSituation && modeEdit" @click="deleteFile('PROOF_TAX_SITUATION')" variant="default"
                                                  class="mb-lg-0 mt-auto" style="margin-bottom: -0.5px !important;">
                                            <font-awesome-icon icon="times"></font-awesome-icon>
                                        </b-button>
                                    </div>
                                </b-col>
                                <b-col lg="12">
                                    <div style="display: flex; flex-direction: row;">
                                        <ValidationProvider :rules="!bankerCoverSheetName ? 'required' : ''" v-slot="{ errors }" :style="bankerCoverSheet && modeEdit ? 'width: 98%' : 'width: 100%'">
                                            <b-form-group :label="$t('bankCover')" id="inputOrderFile" class="mb-2" :invalid-feedback="errors[0]">
                                                <b-form-file v-model="bankerCoverSheet" type="file" accept=".xlsx, .xls, .pdf" size="md"
                                                             :browse-text="$t('select')"
                                                             :disabled="!modeEdit" :placeholder="bankerCoverSheetName ?? ''" :state="errors[0] ? false : null">
                                                </b-form-file>
                                            </b-form-group>
                                        </ValidationProvider>
                                        <b-button v-if="bankerCoverSheet && modeEdit" @click="deleteFile('BANKER_COVER_SHEET')" variant="default"
                                                  class="mb-lg-0 mt-auto" style="margin-bottom: -0.5px !important;">
                                            <font-awesome-icon icon="times"></font-awesome-icon>
                                        </b-button>
                                    </div>
                                </b-col>
                                <b-col lg="12">
                                    <div style="display: flex; flex-direction: row;">
                                        <template v-if="supplierData.requiredRepse && supplierData.id > 0">
                                            <ValidationProvider :rules="!repseName ? 'required' : ''" v-slot="{ errors }" :style="repse && modeEdit ? 'width: 98%' : 'width: 100%'">
                                                <b-form-group :label="$t('repse')" id="inputOrderFile" class="mb-2" :invalid-feedback="errors[0]">
                                                    <b-form-file v-model="repse" type="file" accept=".xlsx, .xls, .pdf" size="md"
                                                                 :browse-text="$t('select')"
                                                                 :disabled="!modeEdit" :placeholder="repseName ?? ''" :state="errors[0] ? false : null">
                                                    </b-form-file>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </template>
                                        <template v-else>
                                            <b-form-group :label="$t('repse')" id="inputOrderFile" class="mb-2" :style="repse ? 'width: 98%' : 'width: 100%'">
                                                <b-form-file v-model="repse" type="file" accept=".xlsx, .xls, .pdf" size="md"
                                                             :browse-text="$t('select')"
                                                             :disabled="!modeEdit" :placeholder="repseName ?? ''">
                                                </b-form-file>
                                            </b-form-group>
                                        </template>
                                        <b-button v-if="repse && modeEdit" @click="deleteFile('REPSE')" variant="default"
                                                  class="mb-lg-0 mt-auto" style="margin-bottom: -0.5px !important;">
                                            <font-awesome-icon icon="times"></font-awesome-icon>
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-col cols="12" class="text-center mt-4" v-show="modeEdit">
                                <b-button type="submit" variant="primary" class="align-content-end" form="supplier-form">
                                    {{ supplierData.id > 0 ? $t('update') : $t('send') }} {{ $t('registration') }}
                                </b-button>
                            </b-col>
                        </b-form>
                    </ValidationObserver>
                </b-card>
            </b-row>
            <br>
        </b-container>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { currencyList, registerUser, editSupplier, jobAreaList, validateSupplierToken } from '@routes';
import * as constants from '@constants';
import $ from 'jquery';

export default {
    name: 'Register',
    data() {
        return {
            username: '',
            password: '',
            supplierData: {
                id: 0,
                isForeignSupplier: false,
                requiredRepse: false,
                legalName: '',
                tradeName: '',
                taxId: '',
                legalEntityType: '',
                industry: '',
                webPage: '',
                currency: '',
                currencyCode: '',
                paymentCount: '',
                bankData: '',
                address: {
                    street: '',
                    extNumber: null,
                    intNumber: null,
                    neighborhood: '',
                    borough: '',
                    city: '',
                    state: '',
                    country: 'México',
                    zipcode: null
                },
                contact:{
                    name: '',
                    jobPosition: '',
                    jobArea: '',
                    jobAreaName: '',
                    phone: null,
                    extension: '',
                    mobile: null,
                    email: ''
                }
            },
            incorporationAct: null,
            proofTaxSituation: null,
            bankerCoverSheet: null,
            repse: null,
            token: '',
            incorporationActName: null,
            proofTaxSituationName: null,
            bankerCoverSheetName: null,
            repseName: null,
            rememberMe: true,
            hasError: false,
            loginError: false,
            breadcrumb: {
                title: this.$t('account'),
                path: []
            },
            isLoading: false,
            states: ['Aguascalientes', 'Baja California Norte', 'Baja California Sur', 'Campeche', 'CDMX', 'Chiapas',
                'Chihuahua', 'Coahuila de Zaragoza', 'Colima', 'Durango', 'Estado de México',
                'Guanajuato', 'Guerrero', 'Hidalgo', 'Jalisco', 'Michoacán de Ocampo', 'Morelos', 'Nayarit',
                'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora',
                'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz de Ignacio de la Llave', 'Yucatán', 'Zacatecas'
            ],
            currencys: ['MXN', 'USD'],
            jobAreas: ['VENTAS'],
            hasRepse: false,
            user: {
                id: 0,
                name: 'User Name',
                email: '',
                username: '',
                roles: '',
                verified: false,
                status: '',
                supplierId: 0,
                supplierLegalName: '',
                reason: ''
            },
            actionUser: 'EDIT',
            language: '',
            DEFAULT_ERROR_MESSAGE: this.$t('DEFAULT_ERROR_MESSAGE')
        };
    },
    mounted() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        
        if (localStorage.getItem('userAction')) {
            this.actionUser = localStorage.getItem('userAction');
        }
        
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
        }
        
        this.language = localStorage.getItem('language');
        
        if (this.user.id <= 0) {
            document.body.classList.remove('margin-left-sidebar');
            document.body.classList.add('no-margin-sidebar');
        }
        
        let $this = this;
        
        extend('required', {
            ...required,
            message: $this.$t('requiredField')
        });
        
        extend('email', {
            ...email,
            message: $this.$t('invalidEmail')
        });
        
        if (this.supplierData.taxId !== '') {
            this.breadcrumb.path.push({
                name: 'register',
                text: `${this.supplierData.taxId + ' / Nuevo'}`
            });
        }

        let currencyData = this.currencys.map(function(currency) {
            return { id: currency, text: currency };
        });

        let jobAreaData = this.jobAreas.map(function(area) {
            return { id: area, text: area };
        });
        let $currency = $('#currency-select');

        this.initSelectState();

        $currency.select2({
            placeholder: $this.$t('currency'),
            allowClear: true,
            language: {
                noResults: () => $this.$t('noResultsFound')
            },
            ajax: {
                url: currencyList(),
                dataType: 'json',
                method: 'GET',
                data: function(params) {
                    return {
                        searchWord: params.term
                    };
                },
                processResults: function(data) {
                    return { results: data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult: function(currency) {
                if (currency.loading) {
                    return $this.$t('searching') + "... <i class='fa fa-spinner fa-pulse fa-fw pull-right'></i>";
                }
                
                return currency.code || currency.text;
            },
            templateSelection: function(currency) {
                if (currency.code === undefined && currency.text === undefined) {
                    return '';
                }
                
                return currency.code || currency.text;
            }
        }).on('select2:select', e => {
            this.supplierData.currency = e.params.data.id;
        });

        $('#jobArea-select').select2({
            placeholder: $this.$t('jobArea'),
            allowClear: true,
            language: {
                noResults: () => $this.$t('noResultsFound')
            },
            ajax: {
                url: jobAreaList(),
                dataType: 'json',
                method: 'GET',
                data: function(params) {
                    return {
                        searchWord: params.term
                    };
                },
                processResults: function(data) {
                    return { results: data };
                }
            },
            escapeMarkup: markup => markup,
            templateResult(jobArea) {
                if (jobArea.loading) {
                    return '<a><span>'+$this.$t('searching')+' ...</span></a>';
                }

                return jobArea.description || jobArea.text;
            },
            templateSelection: function(jobArea) {
                return jobArea.description || jobArea.text;
            }
        }).on('select2:select', e => {
            this.supplierData.contact.jobArea = e.params.data.id;
        }).on('select2:unselect', e => {
            this.supplierData.contact.jobArea = '';
        });
        
        if (this.user.id > 0) {
            this.axios.get(editSupplier(this.user.supplierId)).then(response => {
                if (response.data.code === 'OK') {
                    this.supplierData = response.data.supplierData;
                    
                    if (response.data.supplierFiles.incorporationAct) {
                        this.incorporationAct = [];
                        this.incorporationActName = response.data.supplierFiles.incorporationAct;
                    }

                    if (response.data.supplierFiles.proofTaxSituation) {
                        this.proofTaxSituation = [];
                        this.proofTaxSituationName = response.data.supplierFiles.proofTaxSituation;
                    }

                    if (response.data.supplierFiles.bankerCoverSheet) {
                        this.bankerCoverSheet = [];
                        this.bankerCoverSheetName = response.data.supplierFiles.bankerCoverSheet;
                    }

                    if (response.data.supplierFiles.repse) {
                        this.repse = [];
                        this.repseName = response.data.supplierFiles.repse;
                    }
                } else {
                    this.errorMessage = this.DEFAULT_ERROR_MESSAGE;
                    this.$router.push({ name: 'Login' });
                }
                
                this.$nextTick(() => {
                    if (this.supplierData.id > 0) {
                        $('#jobArea-select').empty().append(new Option(this.supplierData.contact.jobAreaName,
                            this.supplierData.contact.jobArea, true, true))
                            .trigger('change');
                        
                        if (this.supplierData.currency) {
                            $currency.empty().append(new Option(this.supplierData.currencyCode, this.supplierData.currency,
                                true, true))
                                .trigger('change');
                        }
                        
                        if (this.supplierData.address.state) {
                            $('#states-select').val(this.supplierData.address.state).trigger('change');
                        }
                    }
                    
                });
            }).catch(error => {
                this.$router.push({ name: 'Login' });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    methods: {
        onSubmit() {
            this.isLoading = true;
            let $this = this;
            let text = $this.$t('msgSendRegistration');
            text = text.replace('ACTION', (this.user.id === 0 ? $this.$t('send').toLowerCase() : $this.$t('update').toLowerCase()));
            
            this.$swal({
                title: `¡${ $this.$t('attention') }!`,
                text: text,
                icon: 'warning',
                cancelButtonText: $this.$t('cancel'),
                confirmButtonColor: '#3fbf0d',
                confirmButtonText: $this.$t('yes')
            }).then(confirm => {
                if (confirm.isConfirmed) {
                    if (this.user.id === 0) {
                        this.axios.post(validateSupplierToken(), {
                            token: this.token
                        }).then(response => {
                            if (response.data.code === 'OK') {
                                
                                this.loginError = false;
                                let formData = new FormData();

                                formData.append('incorporationAct', this.incorporationAct);
                                formData.append('proofTaxSituation', this.proofTaxSituation);
                                formData.append('bankerCoverSheet', this.bankerCoverSheet);
                                formData.append('repse', this.repse);
                                formData.append('token', this.token);

                                formData.append('supplierData', JSON.stringify(this.supplierData));

                                this.axios.post(registerUser(), formData).then(response => {
                                    if (response.data.code === 'OK') {
                                        this.$bvToast.toast($this.$t('supplierSentSuccess'), {
                                            title: '',
                                            variant: 'success'
                                        });

                                        setTimeout(() => { this.$router.push({ name: 'Login', params: response.data.message }); }, 1500);
                                    } else if (response.data.code === 'NOK' && response.data.message) {
                                        if (response.data.message === 'TAX_ID_REGISTERED') {
                                            this.$bvToast.toast($this.$t('taxIdRegistered'), {
                                                title: '',
                                                variant: 'danger'
                                            });
                                        } else if (response.data.message === 'EMAIL_REGISTERED') {
                                            this.$bvToast.toast($this.$t('emailRegistered'), {
                                                title: '',
                                                variant: 'danger'
                                            });
                                        } else if (response.data.message === 'TOKEN_REVOKED') {
                                            this.$bvToast.toast($this.$t('tokenRevoked'), {
                                                title: '',
                                                variant: 'danger'
                                            });
                                        } else if (response.data.message === 'TOKEN_REDEEMED') {
                                            this.$bvToast.toast($this.$t('tokenRedeemed'), {
                                                title: '',
                                                variant: 'danger'
                                            });
                                        } else if (response.data.message === 'NO_TOKEN_EXIST') {
                                            this.$bvToast.toast($this.$t('NoTokenExist'), {
                                                title: '',
                                                variant: 'danger'
                                            });
                                        } else {
                                            this.$bvToast.toast($this.DEFAULT_ERROR_MESSAGE, {
                                                title: '',
                                                variant: 'danger'
                                            });
                                        }

                                        this.isLoading = false;
                                    } else {
                                        this.$bvToast.toast($this.DEFAULT_ERROR_MESSAGE, {
                                            title: '',
                                            variant: 'danger'
                                        });

                                        this.isLoading = false;
                                    }
                                }).catch(() => {
                                    this.$bvToast.toast($this.DEFAULT_ERROR_MESSAGE, {
                                        title: '',
                                        variant: 'danger'
                                    });

                                    this.isLoading = false;
                                });
                            } else {
                                if (response.data.message === 'TOKEN_REVOKED') {
                                    this.$bvToast.toast($this.$t('tokenRevoked'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                } else if (response.data.message === 'TOKEN_REDEEMED') {
                                    this.$bvToast.toast($this.$t('tokenRedeemed'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                } else if (response.data.message === 'NO_TOKEN_EXIST') {
                                    this.$bvToast.toast($this.$t('NoTokenExist'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                } else {
                                    this.$bvToast.toast($this.DEFAULT_ERROR_MESSAGE, {
                                        title: '',
                                        variant: 'danger'
                                    });
                                }

                                this.isLoading = false;
                            }
                        }).catch(() => {
                            this.$bvToast.toast($this.DEFAULT_ERROR_MESSAGE, {
                                title: '',
                                variant: 'danger'
                            });

                            this.isLoading = false;
                        });
                    } else if (this.user.id > 0) {
                        this.loginError = false;
                        let formData = new FormData();

                        formData.append('incorporationAct', this.incorporationAct);
                        formData.append('proofTaxSituation', this.proofTaxSituation);
                        formData.append('bankerCoverSheet', this.bankerCoverSheet);
                        formData.append('repse', this.repse);
                        formData.append('token', this.token);

                        formData.append('supplierData', JSON.stringify(this.supplierData));

                        this.axios.post(registerUser(), formData).then(response => {
                            if (response.data.code === 'OK') {
                                this.$bvToast.toast($this.$t('updateSuccess'), {
                                    title: '',
                                    variant: 'success'
                                });

                                this.user.status = 'TO_VERIFY';
                                this.user.verified = false;

                                if (this.$parent && this.$parent.user) {
                                    this.$parent.user.status = 'TO_VERIFY';
                                    this.$parent.user.verified = false;
                                }

                                localStorage.setItem('user', JSON.stringify(this.user));

                                this.actionUser = 'DETAIL';

                                this.isLoading = false;
                            } else if (response.data.code === 'NOK' && response.data.message) {
                                if (response.data.message === 'TAX_ID_REGISTERED') {
                                    this.$bvToast.toast($this.$t('taxIdRegistered'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                } else if (response.data.message === 'EMAIL_REGISTERED') {
                                    this.$bvToast.toast($this.$t('emailRegistered'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                } else if (response.data.message === 'TOKEN_REVOKED') {
                                    this.$bvToast.toast($this.$t('tokenRevoked'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                } else if (response.data.message === 'TOKEN_REDEEMED') {
                                    this.$bvToast.toast($this.$t('tokenRedeemed'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                } else if (response.data.message === 'NO_TOKEN_EXIST') {
                                    this.$bvToast.toast($this.$t('NoTokenExist'), {
                                        title: '',
                                        variant: 'danger'
                                    });
                                } else {
                                    this.$bvToast.toast($this.DEFAULT_ERROR_MESSAGE, {
                                        title: '',
                                        variant: 'danger'
                                    });
                                }
                                
                                this.isLoading = false;
                            } else {
                                this.$bvToast.toast($this.DEFAULT_ERROR_MESSAGE, {
                                    title: '',
                                    variant: 'danger'
                                });

                                this.isLoading = false;
                            }
                        }).catch(() => {
                            this.$bvToast.toast($this.DEFAULT_ERROR_MESSAGE, {
                                title: '',
                                variant: 'danger'
                            });

                            this.isLoading = false;
                        });
                    }
                } else {
                    this.isLoading = false;
                }
            });
        },
        isForeign() {
            this.supplierData.isForeignSupplier = !this.supplierData.isForeignSupplier;
            if (this.supplierData.isForeignSupplier) {
                this.supplierData.address.country = '';
            } else {
                this.supplierData.address.country = 'México';
            }
            
            this.bankerCoverSheetName = '';
            this.bankerCoverSheet = null;
            this.repse = null;
            this.repseName = '';
            this.proofTaxSituation = null;
            this.proofTaxSituationName = '';
            this.incorporationAct = null;
            this.incorporationActName = '';
        },
        initSelectState() {
            let statesData = this.states.map(function(state) {
                return { id: state, text: state };
            });
            let $state = $('#states-select');
            let $this = this;

            $state.select2({
                placeholder: $this.$t('state'),
                allowClear: true,
                language: {
                    noResults: () => $this.$t('noResultsFound')
                },
                data: statesData,
                escapeMarkup: markup => markup,
                templateResult(state) {
                    if (state.loading) {
                        return '<a><span>'+$this.$t('searching')+' ...</span></a>';
                    }

                    return state.text;
                },
                templateSelection: function(state) {
                    return state.text || state.text;
                }
            }).on('select2:select', e => {
                this.supplierData.address.state = e.params.data.id;
            }).on('select2:unselect', e => {
                this.supplierData.address.state = '';
            });
        },
        deleteFile(field) {
            switch (field) {
                case 'BANKER_COVER_SHEET':
                    this.bankerCoverSheetName = '';
                    this.bankerCoverSheet = null;
                    break;
                case 'REPSE':
                    this.repse = null;
                    this.repseName = '';
                    break;
                case 'PROOF_TAX_SITUATION':
                    this.proofTaxSituation = null;
                    this.proofTaxSituationName = '';
                    break;
                case 'INCORPORATION_ACT':
                    this.incorporationAct = null;
                    this.incorporationActName = '';
                    break;
            }
        },
        changeLanguage(value) {
            this.language = value;
            localStorage.setItem('language', this.language);
            location.reload();
        }
    },
    watch: {
        // 'supplierData.isForeignSupplier'() {
        //     console.log(this.supplierData.isForeignSupplier);
        // }
    },
    computed: {
        modeEdit() {
            return (this.actionUser === 'EDIT');
        }
    }
};
</script>

<style>
.v-center {
    margin-top: 30vh;
}
.has-error .select2-selection--single {
    border-color: #cc5965 !important;
}
</style>